import { ChangeEvent, useEffect, useRef, useState, KeyboardEvent } from "react";
import { ChatArrowIcon } from "../Icons/ChatArrow";
import styles from './input.module.css'
// import AnimatedInputField from '@/components/Chat/AnimatedInputField'

interface IProps {
  input: string;
  handleInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: any;
}

export default function ChatInput(props: IProps) {
  const {
    input,
    handleInputChange,
    handleSubmit
  } = props

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const [showScrollbar, setShowScrollbar] = useState(false)

  function adjustHeight() {
    const textarea = textareaRef.current
    if (!textarea) return;

    const minLines = 3
    const yPad = 16
    textarea.style.height = 'auto'
    const newHeight = Math.min(textarea.scrollHeight, minLines * 24 + yPad); // Assuming 1 row is 24px
    textarea.style.height = `${newHeight}px`;
    setShowScrollbar(textarea.scrollHeight > newHeight)
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    adjustHeight()
  }, [input])

  return (
    <form onSubmit={handleSubmit} className="relative w-full pt-10 pb-5 px-4 lg:py-5">
      <div
        className={styles.textarea}
      >
        <textarea
          ref={textareaRef}
          className={`py-2 px-3 pr-8 w-full text-black placeholder-black font-light resize-none focus:outline-none
        ${showScrollbar ? 'overflow-y-auto rounded-2xl' : 'overflow-y-hidden rounded-full'}
        `}
          placeholder="Type your business question here"
          value={input}
          rows={1}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <div
          className='flex items-center absolute right-3 inset-y-0 z-[9999px] pb-1.5'
        >
          <ChatArrowIcon
            allowDarkMode={false}
            onClick={handleSubmit}
          />
        </div>
      </div>
      {/*
      <AnimatedInputField
        input={input}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      />
      <input
        ref={ref}
        className="py-2 px-3 w-full text-black placeholder-gray-placeholder font-light rounded border border-border-default rounded-full"
        placeholder="Type your business question here"
        value={input}
        onChange={handleInputChange}
      />
      <button
        type="submit"
        className="rounded-full px-6 py-2 dark:bg-white dark:text-black bg-action-primary-default text-white"
      >
        Send
      </button>
      */}
    </form>
  )
}
