import { IconProps } from "@/utils/types"
import { EnvelopeIcon } from "@heroicons/react/24/outline";

export function InternalEnvelopIcon(props: IconProps) {
  return (
    <div {...props}>
      <EnvelopeIcon
        className="h-6 w-6 text-black dark:text-white font-light"
      />
    </div>
  )
}

