import { useEffect, useState } from "react"
import { useAuth } from "@clerk/nextjs"
import { Consent } from "../types"
import { getAcceptTermsDetails, submitAcceptTermsDetails } from "../requests"
import { generateConsentRequest } from "../utils"

export default function useTermsAcceptance() {
  const { isSignedIn, userId, getToken, sessionId } = useAuth()
  const [accepted, setAccepted] = useState(false)
  const [loading, setLoading] = useState(true)

  async function acceptTerms(consentText: string) {
    const currentDate = new Date()
    const UTCTime = currentDate.toUTCString()
    if (isSignedIn) {
      const token = await getToken({ template: 'supabase' })
      // const consentRequestData = generateConsentRequest({ consentText })
      await submitAcceptTermsDetails({ userId, sessionId, token })
      // Cache consent info locally
      localStorage.setItem(`consent-${userId}`, JSON.stringify({
        accepted: true,
        timestamp: UTCTime,
      }))
    } else {
      localStorage.setItem(`consent-anonymous`, JSON.stringify({
        accepted: true,
        timestamp: UTCTime,
      }))
    }
    setAccepted(true)
  }

  async function checkConsent() {
    try {
      setLoading(true)
      let result = checkLocalConsentDetails()
      if (result) {
        setAccepted(true)
      } else if (isSignedIn) {
        result = await checkRemoteConsentDetails()
        setAccepted(result)
      } else {
        setAccepted(false)
      }
    } catch (error) {
      console.error(error)
      setAccepted(false)
    } finally {
      setLoading(false)
    }
  }

  function isCacheExpired(consent: Consent, age: number) {
    const UTCTime = consent.timestamp
    const currentTime = new Date()
    const acceptanceTime = new Date(UTCTime)
    const elapsedTime = currentTime.valueOf() - acceptanceTime.valueOf()
    if (elapsedTime > age) {
      // If anon user accepted terms more than 1 day ago, have them accept terms
      // once again.
      return true
    }
    return false
  }

  async function checkRemoteConsentDetails(): Promise<boolean> {
    if (!isSignedIn) return false;

    const token = await getToken({ template: 'supabase' })
    const consent: Consent = await getAcceptTermsDetails({ token, userId })
    if (consent) {
      // Cache consent info locally
      localStorage.setItem(`consent-${userId}`, JSON.stringify({
        ...consent,
        timestamp: (new Date()).toUTCString()
      }))
      return consent.accepted
    } else {
      return false
    }
  }

  function checkLocalConsentDetails(): boolean {
    if (!isSignedIn) {
      // Do not cache result
      return false
      /*
      const consentDetails = localStorage.getItem('consent-anonymous')
      if (!consentDetails) {
        return false
      } else {
        const consent: Consent = JSON.parse(consentDetails)
        if (isCacheExpired(consent, 24 * 60 * 60 * 1000)) {
          return false
        }
        return true
      }
      */
    } else {
      const consentDetails = localStorage.getItem(`consent-${userId}`)
      if (!consentDetails) {
        return false
      } else {
        const consent: Consent = JSON.parse(consentDetails)
        if (isCacheExpired(consent, 24 * 60 * 60 * 1000)) {
          return false
        }
        return consent.accepted
      }
    }
  }

  useEffect(() => {
    checkConsent()
  }, [isSignedIn])

  return { accepted, acceptTerms, loading }
}
