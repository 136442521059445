import { Dialog, Transition } from '@headlessui/react'
import { Dispatch, Fragment, SetStateAction, useState } from 'react'
import Button from '../Button';
import { FeedbackModalContext } from '@/utils/types';
import { submitMessageFeedback } from '@/utils/requests';
import Spinner from '../Spinner';
import { trackFlowComplete } from '@/utils/analytics';

interface FeedbackModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>
  context: FeedbackModalContext;
}

const feedbackOptions = [
  "Great response!",
  "This advice isn't useful.",
  "I didn't ask for this information."
]

export default function FeedbackModal({
  isOpen,
  setIsOpen,
  context,
}: FeedbackModalProps) {
  const [feedback, setFeedback] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  async function handleSubmit() {
    setLoading(true)
    setError('')
    try {
      // const token = await getToken({ template: 'supabase' }) as string
      await submitMessageFeedback({
        feedbackContext: {
          ...context,
          feedbackMessage: feedback,
          feedbackIdx: feedbackOptions.indexOf(feedback),
        }
      })

      closeModal()
    } catch (error) {
      setError((error as Error).message)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  function closeModal() {
    setFeedback("")
    setIsOpen(false)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[363px] bg-orange-100 dark:bg-black transform overflow-hidden rounded-3xl py-5 px-8 text-left align-middle shadow-xl transition-all">
                  <div className=''>
                    <label htmlFor="feedback" className="block leading-6 text-black dark:text-white">
                      Please provide some feedback on this answer so we can continue to improve your experience:
                    </label>
                    <div className="my-4 space-y-3">
                      {feedbackOptions.map((feedbackOption, idx) => (
                        <div key={idx}
                          onClick={() => setFeedback(feedbackOption)}
                          className={`${feedback === feedbackOption ? 'border-orange' : 'border-white'} border-2 rounded-full bg-white p-2 px-4 text-center cursor-pointer`}
                        >
                          <p
                            className='text-black'
                          >
                            {feedbackOption}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  {error ?
                    <p className='text-orange-600'>
                      {error}
                    </p>
                    : null}

                  <div className="mt-6 flex justify-center items-center space-x-2">
                    <Button
                      onClick={() => {
                        handleSubmit()
                        trackFlowComplete({
                          flowName: "feedback",
                          clickName: "submit",
                          optionSelected: feedback.toLowerCase().slice(0, feedback.length - 1).replaceAll(' ', '_'),
                        })
                      }}
                      className='px-5 flex justify-center items-center border border-transparent dark:hover:border-orange hover:shadow-lg duration-150'
                      disabled={loading}
                    >
                      Submit
                      {loading ? <Spinner className="ml-3" /> : null}
                    </Button>
                    <button
                      onClick={() => {
                        closeModal()
                        trackFlowComplete({ flowName: "feedback", clickName: "close" })
                      }}
                      className='text-[16px] py-2.5 w-full rounded-full px-5 flex justify-center items-center bg-transaparent border border-transparent hover:border-black dark:hover:border-white dark:text-white duration-150'
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
