import { IconProps } from "@/utils/types"

export function ChatBubbleIcon(props: IconProps) {
  return (
    <div {...props}>
      <svg className="dark:fill-editable-textbox-light fill-black" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.0005 5.5H2V6H7.0005V5.5Z" />
        <path d="M6 7H2V7.5H6V7Z" />
        <path d="M2.5005 11.647C2.436 11.647 2.371 11.6345 2.309 11.6085C2.1215 11.531 2 11.3495 2 11.1465V9.5H1.75C1.061 9.5 0.5 8.9395 0.5 8.25V5.25C0.5 4.5605 1.061 4 1.75 4H7.75C8.439 4 9 4.5605 9 5.25V8.25C9 8.9395 8.439 9.5 7.75 9.5H4.8535L2.8535 11.5C2.758 11.5955 2.6305 11.647 2.5005 11.647ZM1.75 4.5C1.3365 4.5 1 4.8365 1 5.25V8.25C1 8.6635 1.3365 9 1.75 9H2.5V11.1465L4.6465 9H7.75C8.1635 9 8.5 8.6635 8.5 8.25V5.25C8.5 4.8365 8.1635 4.5 7.75 4.5H1.75Z" />
        <path d="M10.75 6H9.5V5.5H10.75C11.1635 5.5 11.5 5.1635 11.5 4.75V1.75C11.5 1.3365 11.1635 1 10.75 1H4.75C4.3365 1 4 1.3365 4 1.75V3.5H3.5V1.75C3.5 1.0605 4.061 0.5 4.75 0.5H10.75C11.439 0.5 12 1.0605 12 1.75V4.75C12 5.4395 11.439 6 10.75 6Z" />
      </svg>
    </div>
  )
}
