import { IconProps } from "@/utils/types"

interface CopyIconProps extends IconProps {
  fill?: string;
}

export function CopyIcon(props: CopyIconProps) {
  const fill = props.fill ?? "#141413"

  return (
    <div {...props}>
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1528 0.919434H3.97871C3.23925 0.919434 2.63471 1.52397 2.63471 2.26343V3.58149H1.84595C1.10649 3.58149 0.501953 4.18603 0.501953 4.92549V11.6507C0.501953 12.3901 1.10649 12.9947 1.84595 12.9947H9.02001C9.75947 12.9947 10.364 12.3901 10.364 11.6507V10.3326H11.1528C11.8922 10.3326 12.4968 9.72808 12.4968 8.98862V2.26343C12.4968 1.52397 11.8922 0.919434 11.1528 0.919434ZM9.91514 11.6533C9.91514 12.1462 9.51039 12.551 9.01741 12.551H1.84336C1.35039 12.551 0.945629 12.1462 0.945629 11.6533V4.92808C0.945629 4.43511 1.35039 4.03035 1.84336 4.03035H9.01741C9.51039 4.03035 9.91514 4.43511 9.91514 4.92808V11.6533ZM12.0505 8.98862C12.0505 9.4816 11.6457 9.88635 11.1528 9.88635H10.364V4.92808C10.364 4.18862 9.75947 3.58408 9.02001 3.58408H3.08098V2.26603C3.08098 1.77306 3.48574 1.3683 3.97871 1.3683H11.1528C11.6457 1.3683 12.0505 1.77306 12.0505 2.26603V8.99122V8.98862Z" fill={fill} />
      </svg>
    </div>
  )
}


