import { Message } from "ai";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { a, code, h1, h2, h3, hr, li, ol, p } from "@/components/Chat/MarkdownComponents";
import { CopyIcon } from "../Icons/Copy";
import { useDarkMode } from "@/utils/hooks/useDarkMode";
import { useEffect, useState } from "react";
import { ChatBubbleIcon } from "../Icons";
import URLCard from "./URLCard";
import { NewChatIcon } from "../Icons/NewChatIcon";
import { trackChatClick, trackFlowStart } from "@/utils/analytics";

interface IProps {
  message: Message;
  handleModalOpen: () => void;
  startNewChat: () => void;
}

export default function ChatMessage({ handleModalOpen, message, startNewChat }: IProps) {
  const { isDarkMode } = useDarkMode()
  const [copyText, setCopyText] = useState<'Copy' | 'Copied!'>('Copy')
  const [urls, setUrls] = useState<{
    text: string;
    url: string;
  }[]>([])

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(message.content);
      setCopyText('Copied!')
      setTimeout(() => setCopyText('Copy'), 2000)
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  function extractLinks() {
    const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g;

    const matches = Array.from(message.content.matchAll(linkRegex))

    const links = matches.map(match => ({
      text: match[1],
      url: match[2],
    }));

    setUrls(links)
  }

  useEffect(() => {
    if (message.role === 'assistant') {
      extractLinks()
    }
  }, [])

  if (message.role === "user") {
    return (
      <div
        key={message.id}
        className="whitespace-pre-wrap text-sm text-black bg-white dark:bg-black dark:text-white rounded-3xl px-4 py-2 w-fit ml-auto max-w-sm"
      >
        {message.content}
      </div>
    )
  } else if (message.role === "assistant") {
    return (
      <div className="flex items-start space-x-2">
        <div
          key={message.id}
          className="whitespace-normal text-sm text-black bg-white text-black dark:text-white dark:bg-black rounded-3xl px-4 py-2 mr-auto max-w-sm border border-orange dark:border-white"
        >
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={{
              a,
              h1,
              h2,
              h3,
              hr,
              ol,
              li,
              p,
              code,
            }}
          >
            {message.content.replaceAll('[Small Business AI]: ', '')}
          </Markdown>

          <div className="space-y-2 my-2">
            {urls.map((url, idx) => (
              <URLCard url={url} key={idx} />
            ))}
          </div>

          <div className="flex space-x-2 pt-2 pb-1">
            <div
              onClick={() => {
                handleModalOpen()
                trackChatClick("feedback")
                trackFlowStart({ flowName: "feedback", clickName: "feedback" })
              }}
              className="flex space-x-1 items-center cursor-pointer">
              <ChatBubbleIcon />
              <span className="text-black dark:text-editable-textbox-light">Feedback</span>
            </div>
            <div
              onClick={() => {
                copyToClipboard()
                trackChatClick("copy")
              }}
              className="flex space-x-1 items-center cursor-pointer">
              <CopyIcon fill={isDarkMode ? '#F1EFEB' : '#141413'} />
              <span className="text-black dark:text-editable-textbox-light">{copyText}</span>
            </div>
            <div
              onClick={() => {
                startNewChat()
                trackChatClick("new_chat")
              }}
              className="flex space-x-1 items-center cursor-pointer"
            >
              <NewChatIcon fill={isDarkMode ? '#F1EFEB' : '#141413'} />
              <span className="text-black dark:text-editable-textbox-light">New chat</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}
