import React, { useEffect, useState } from 'react';
import { ArrowTopRightIcon } from '../Icons/ArrowTopRight';
import { useDarkMode } from '@/utils/hooks/useDarkMode';

interface URLCardProps {
  url: {
    text: string;
    url: string;
  };
}

// Function to extract the hostname from a URL
const getHostName = (url: string) => {
  try {
    const { hostname } = new URL(url);
    if (hostname.startsWith('www')) {
      return hostname.substring(4)
    }
    return hostname;
  } catch (error) {
    return 'Invalid URL';
  }
};

const URLCard = ({ url }: URLCardProps) => {
  const { isDarkMode } = useDarkMode()
  const [pageTitle, setPageTitle] = useState(url.text);

  const handleClick = () => {
    window.open(url.url, '_blank');
  };

  /*
  useEffect(() => {
    // Fetch the webpage and extract the title
    const fetchPageTitle = async () => {
      try {
        const response = await fetch(`/api/url-title?url=${url.url}`);
        const { title } = await response.json();
        if (title) {
          setPageTitle(title);
        }
      } catch (error) {
        console.error(error)
      }
    };

    fetchPageTitle();
  }, [url]);
  */

  const hostName = getHostName(url.url);

  return (
    <div onClick={handleClick} className="flex items-center justify-between rounded-full bg-orange dark:bg-white text-white dark:text-black px-6 py-4 cursor-pointer hover:shadow-md">
      <div className='flex-1 max-w-[95%]'>
        <h2 className="text-sm text-white dark:text-orange font-medium">{hostName}</h2>
      </div>
      <ArrowTopRightIcon fill={isDarkMode ? '#CF4500' : '#FFF'} />
    </div>
  );
};

export default URLCard;

