import { IconProps } from "@/utils/types"

export function GlobeIcon(props: IconProps) {
  return (
    <div {...props}>
      <svg className="dark:fill-white fill-black" width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9602 0C5.17021 0 0.460205 4.71 0.460205 10.5C0.460205 16.29 5.17021 21 10.9602 21C16.7502 21 21.4602 16.29 21.4602 10.5C21.4602 4.71 16.7502 0 10.9602 0ZM10.9602 19.999C9.76921 19.999 8.52621 18.507 7.7612 16H14.1592C13.3942 18.507 12.1512 19.999 10.9602 19.999ZM7.4992 15C7.1982 13.695 7.01621 12.182 7.01621 10.499C7.01621 8.817 7.1982 7.304 7.4992 6H14.4222C14.7232 7.304 14.9052 8.817 14.9052 10.499C14.9052 12.182 14.7232 13.695 14.4222 15H7.4992ZM10.9602 1C12.1512 1 13.3952 2.493 14.1592 5H7.7612C8.5252 2.493 9.76921 1 10.9602 1ZM15.4382 6H19.3242C20.0482 7.34 20.4602 8.873 20.4602 10.5C20.4602 12.127 20.0482 13.66 19.3242 15H15.4382C15.7352 13.641 15.9052 12.122 15.9052 10.499C15.9042 8.877 15.7352 7.358 15.4382 6ZM18.6922 5H15.1882C14.7512 3.467 14.1382 2.202 13.3982 1.329C15.5632 1.905 17.4242 3.223 18.6922 5ZM8.52221 1.329C7.78321 2.202 7.1692 3.467 6.7332 5H3.2282C4.4962 3.223 6.35721 1.905 8.52221 1.329ZM2.5962 6H6.4822C6.1852 7.358 6.01621 8.877 6.01621 10.499C6.01621 12.122 6.1862 13.641 6.4832 15H2.5962C1.87221 13.66 1.46021 12.127 1.46021 10.5C1.46021 8.873 1.87221 7.34 2.5962 6ZM3.2282 16H6.7332C7.1702 17.533 7.7842 18.799 8.5232 19.672C6.3582 19.095 4.4962 17.777 3.2282 16ZM13.3962 19.672C14.1362 18.799 14.7492 17.533 15.1862 16H18.6912C17.4242 17.777 15.5622 19.095 13.3962 19.672Z" fill="inherit"/>
      </svg>
    </div>
  )
}
