import Link from 'next/link';

const disclaimer = `Your chats with and inputs to our chatbot are received and processed by Mastercard and our third-party service providers to improve the product. Given the probabilistic nature of AI, we cannot and do not guarantee the information provided by our chatbot will be complete, accurate, or up to date.\n\nThe chatbot is not intended to provide professional advice and  for informational use purposes only. You agree not to input any proprietary, confidential, or personal data, or any other content that you do not have the right to use into the chatbot. The chatbot may from time to time recommend Mastercard products and services.\n\nAny third party products or services are not endorsed by Mastercard.\n\n`

interface ToSConsentProps {
  handleAccept: (consentText: string) => Promise<void>
}

const ToSConsent = ({ handleAccept }: ToSConsentProps) => {
  const consentText = `${disclaimer}\nBy using this chatbot, you have read and agree to the Chatbot Terms.`

  return (
    <div className="space-y-10">
      <div>
        <h1 className="text-xl sm:text-h1 text-orange dark:text-orange-200">
          <span className='inline-block'>Hello, welcome to your</span>{' '}
          <span className='inline-block'>small business</span>{' '}
          <span className='inline-block'>mentorship journey.</span>
        </h1>
        <h2 className="text-xl sm:text-h2 font-light dark:text-white text-black">How can I help you today?</h2>
      </div>

      <div>
        <div className='overflow-hidden'>
          <div className={`max-w-[1000px] text-sm sm:text-base rounded-t-3xl bg-white dark:bg-black p-5 mt-0 duration-200 transition-[margin]`}>
            <p className='whitespace-pre-line text-black dark:text-white'>
              {disclaimer}
            </p>
            <p className='max-w-[1000px] text-black dark:text-white'>
              By using this chatbot, you have read and agree to the <Link prefetch={true} className='text-orange dark:text-orange-200 underline' href='/terms-of-use'>Chatbot Terms</Link>.
            </p>
          </div>
        </div>
        <div className={`max-w-[1000px] bg-white dark:bg-black px-5 pb-5 rounded-b-3xl transition-[padding] duration-200 motion-reduce:transition-none`}>
          <button
            onClick={() => handleAccept(consentText)}
            className="h-[50px] px-5 py-2.5 bg-orange dark:bg-white text-sm sm:text-base text-white dark:text-black rounded-full hover:shadow-lg"
          >
            Accept Terms of Use
          </button>
        </div>
      </div>
    </div>
  );
};

export default ToSConsent;

