import Lottie from "lottie-react"
import animationData from "@/components/lottie/Ripple_Idle.json"

export default function IdleSignifier() {
  return (
    <div className="-m-10" aria-hidden="true">
      <Lottie
        aria-hidden="true"
        animationData={animationData}
        loop={true}
      />
    </div>
  )
}

