"use client";

import { Message } from "ai/react";
import { useAuth } from "@clerk/nextjs";
import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import useAssistant from "@/components/useAssistant";
import { useIdleTimer } from "react-idle-timer";

import ChatInput from "@/components/Chat/Input";
import ChatMessage from "@/components/Chat/ChatMessage";
import { FeedbackModalContext, LogoSize, SignifierState } from "@/utils/types";
import Logo from "@/components/Logo";
import Loading from "@/components/Loading";
import FeedbackModal from "@/components/Modals/FeedbackModal";
import {
  setDIHomePage,
  trackChatbotClose,
  trackChatbotStart,
  trackStageSelection,
} from "@/utils/analytics";
import SidebarAccordion from "@/components/SidebarAccordion";
import IdleSignifier from "@/components/Signifier/Idle";
import ThinkingSignifier from "@/components/Signifier/Thinking";
import ChatIntro from "@/components/Chat/Intro";
import ToSConsent from "@/components/TermsOfUseConsent";
import Legal from "@/components/Sidebar/Legal";
import { useDarkMode } from "@/utils/hooks/useDarkMode";
import useTermsAcceptance from "@/utils/hooks/useTermsAcceptance";
import Button from "@/components/Button";
import { useSetAtom } from "jotai";
import { authState, isProfileDropdownOpen } from "@/utils/atoms/sidebar";
import { toast } from "react-hot-toast";

export default function Chat() {
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const messagesListRef = useRef<HTMLDivElement>(null);
  const signifierContainerRef = useRef<HTMLDivElement>(null);
  const signifierRef = useRef<HTMLDivElement>(null);

  const { isLoaded, isSignedIn, userId, getToken, signOut } = useAuth();
  const { isDarkMode } = useDarkMode();
  const {
    accepted: termsAccepted,
    acceptTerms,
    loading,
  } = useTermsAcceptance();

  // Logout user after 15 minutes of idleness
  const { getRemainingTime } = useIdleTimer({
    onIdle: async () => {
      // Track chatbot close
      trackChatbotCloseHelper();

      await signOut();
      window.location.reload();
    },
    disabled: userId ? false : true,
    timeout: 15 * 60 * 1000, // 15 minutes idle timeout
  });


  useEffect(() => {
    //check locale
    const checkLocale = async () => {
      const response = await fetch("/api/locale");
      const res = await response.json();
      if (res.error === "Not in the US") {
        window.location.href = "/thank-you-redirect";
        return;
      } else {
        console.error(res.error);
        return;
      }
    };
    checkLocale();
  }, []);

  const setAuthState = useSetAtom(authState);
  const setIsProfileDropdownOpen = useSetAtom(isProfileDropdownOpen);

  const [chatStartTime, setChatStartTime] = useState<Date | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [threadId, setThreadId] = useState<string | null>(null);
  const [historicalMessages, setHistoricalMessages] = useState<Message[]>([]);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState<number | null>(null);
  const [feedbackModalContext, setFeedbackModalContext] =
    useState<FeedbackModalContext>({
      threadId: isSignedIn ? threadId : null,
      assistantResponse: "",
      assistantResponseId: "",
    });
  // Flag to refresh user's list of threads
  const [refreshThreads, setRefreshThreads] = useState(false);

  // TODO: Handle error
  const {
    status,
    messages,
    input,
    threadId: vercelThreadId,
    setThreadId: setVercelThreadId,
    submitMessage,
    handleInputChange,
    error,
    clearMessages,
    setMessages,
  } = useAssistant({
    api: "/api/assistant-copy",
    body: {
      userId: userId,
      token: token,
      thread: threadId,
      loggedIn: isSignedIn,
    },
  });

  function trackChatbotCloseHelper() {
    if (chatStartTime) {
      trackChatbotClose(chatStartTime);
      setChatStartTime(null);
    }
  }

  // Open feedback modal with proper context around the message
  function openFeedbackModal(response: string, responseId: string) {
    setFeedbackModalContext({
      threadId: isSignedIn ? (threadId ? threadId : vercelThreadId!) : null,
      assistantResponse: response,
      assistantResponseId: responseId,
    });
    setFeedbackModalOpen(true);
  }

  function startNewChat() {
    setSelectedStage(null);
    setHistoricalMessages([]);
    setThreadId(null);
    setVercelThreadId(undefined);
    setMessages([]);
    setRefreshThreads(!refreshThreads);

    trackChatbotCloseHelper();
  }

  function updateSignifierPosition(role: string) {
    const container = messagesListRef.current;
    if (!container || !signifierRef.current || !signifierContainerRef.current)
      return;

    // Update signifier container height to match height of messages list
    const { height } = container.getBoundingClientRect();
    signifierContainerRef.current.style.height = `${height}px`;

    if (role === "assistant") {
      signifierRef.current.style.top = "";
      signifierRef.current.style.bottom = "-12px";
    } else if (role === "user") {
      const lastMessage = container.children.item(
        container.children.length - 1
      );
      if (!lastMessage) return;
      const { height: blobHeight } = lastMessage.getBoundingClientRect();
      const padding = 12;
      signifierRef.current.style.bottom = `${Math.max(
        0,
        blobHeight + padding
      )}px`;
    } else {
      // No role is provided. This occurs when a new chat is triggered
      signifierRef.current.style.top = "0px";
      signifierRef.current.style.bottom = "";
    }
  }

  useEffect(() => {
    const visitorType = isSignedIn ? "Logged-In" : "Guest";
    setDIHomePage({ visitorType });
  }, [isSignedIn]);

  useEffect(() => {
    window.onbeforeunload = trackChatbotCloseHelper;
  }, []);

  const handleThreadClick = (thread: any) => {
    const mappedMessages = thread.messages.map((m: any) => {
      return {
        content: m.content[0].text.value,
        id: m.id,
        role: m.role,
      };
    });
    const threadId = thread.thread_id;
    if (thread.selected_stage !== null) {
      setHistoricalMessages(mappedMessages.splice(1));
    } else {
      setHistoricalMessages(mappedMessages);
    }
    // setMessages(mappedMessages);
    setSelectedStage(thread.selected_stage);
    clearMessages();
    setThreadId(threadId);
    setVercelThreadId(threadId);
    setRefreshThreads(!refreshThreads);

    trackChatbotCloseHelper();
  };

  async function deleteThread(deleteThreadId: string) {
    const fetchToken = async () => {
      const fetchedToken = await getToken({ template: "supabase" });
      return fetchedToken;
    };
    const token = await fetchToken();

    if (!userId || !token) {
      return;
    }

    const resp = await fetch(`/api/delete-thread?threadId=${deleteThreadId}`, {
      method: "DELETE",
      headers: {
        "x-clerk-user-jwt": token,
        "x-clerk-user-id": userId,
      },
    });
    const data = await resp.json();
    if (deleteThreadId === vercelThreadId) {
      startNewChat();
    }
  }

  const handleSelectStage = async (
    selectedStage: number,
    stageMessage: string
  ) => {
    // Track chatbot start
    trackChatbotStart();
    setChatStartTime(new Date());

    const payload = {
      userId: userId,
      token: token,
      stage: selectedStage,
      thread: threadId,
      loggedIn: isSignedIn,
      message: stageMessage,
    };
    setSelectedStage(selectedStage);

    try {
      const response = await fetch("/api/assistant-copy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to submit stage");
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      while (true) {
        const { done, value } = await reader!.read();
        if (done) break;

        // Decode the chunk of data
        buffer += decoder.decode(value, { stream: true });

        // Process each chunk (e.g., split by newlines)
        let parts = buffer.split("\n");

        // The last part might be incomplete, so save it for the next chunk
        buffer = parts.pop()!;

        for (let part of parts) {
          // Strip the length prefix (e.g., "5:")
          let jsonStr = part.replace(/^\d+:/, "");

          try {
            const data = JSON.parse(jsonStr);
            if (data.threadId) {
              setThreadId(data.threadId);
              setVercelThreadId(data.threadId);
            }

            if (data.content) {
              const content = data.content
                .map((c: any) => c.text.value)
                .join(" ");
              console.log("Assistant response content:", content);
              setHistoricalMessages([
                {
                  id: data.id,
                  role: data.role,
                  content: data.content[0].text.value,
                },
              ]);
            }
          } catch (error) {
            console.error("Failed to parse streamed data", error);
          }
        }
      }

      if (buffer.length > 0) {
        // Process any remaining data in the buffer
        const jsonStr = buffer.replace(/^\d+:/, "");
        const data = JSON.parse(jsonStr);
        console.log("Final chunk data:", data);
      }
    } catch (error) {
      console.error("Error submitting stage:", error);
    } finally {
      setRefreshThreads(!refreshThreads);
    }
  };

  useEffect(() => {
    const fetchToken = async () => {
      const fetchedToken = await getToken({ template: "supabase" });
      setToken(fetchedToken);
    };

    fetchToken();
  }, [getToken]);

  useEffect(() => {
    try {
      const errorMessage = JSON.parse(error?.message || "")
      if (errorMessage.error) {
        toast.error(errorMessage.error, {
          duration: 10000,
          position: 'top-right'
        })
      }
    } catch (err) {
      // supress error
    }
  }, [error])

  const [sidebarOpen, setSidebarOpen] = useState(false);

  {
    /*
  // When status changes to accepting messages, focus the input:
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (status === "awaiting_message") {
      inputRef.current?.focus();
    }
  }, [status]);
  */
  }

  useEffect(() => {
    if (!messageContainerRef.current) return;
    // Scroll to bottom of container to reveal latest message
    messageContainerRef.current.scrollTop =
      messageContainerRef.current.scrollHeight;
    if (messages.length) {
      setTimeout(() =>
        updateSignifierPosition(messages[messages.length - 1].role)
      );
    }

    // Track chatbot start for a new thread as well as resuming an earlier thread
    if (messages.length === 1) {
      trackChatbotStart();
      setChatStartTime(new Date());
    }
  }, [messages.length]);

  // if messages.last is from assistant and contains [Small Business AI] then show toast
  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1]
      if (lastMessage.role === 'assistant' && lastMessage.content.length > 0) {
        const lastMessageContent = lastMessage.content
        if (lastMessageContent.includes('[Small Business AI]')) {
          toast.error('Please be aware that your recent inputs are violating our guidelines, if this continues your account will be removed.')
        }
      }
    }
  }, [messages])

  useEffect(() => {
    if (!messageContainerRef.current) return;
    // Scroll to bottom of container to reveal latest message
    messageContainerRef.current.scrollTop =
      messageContainerRef.current.scrollHeight;
    if (historicalMessages.length) {
      setTimeout(() =>
        updateSignifierPosition(
          historicalMessages[historicalMessages.length - 1].role
        )
      );
    } else {
      updateSignifierPosition("");
    }
  }, [historicalMessages]);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden w-full max-w-sm"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex w-full max-w-sm">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full flex-1">
                {/*
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                */}

                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="pt-6 h-screen w-full [@media(max-width:400px)]:w-[280px] w-[280px] flex justify-start grow flex-col bg-white dark:bg-black pb-12">
                  <button
                    type="button"
                    className="ml-auto mx-6 text-black"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-black dark:text-editable-textbox-light"
                      aria-hidden="true"
                    />
                  </button>
                  <div className="px-6 overflow-auto">
                    <SidebarAccordion
                      handleThreadClick={handleThreadClick}
                      showLegal={false}
                      deleteThread={deleteThread}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden overflow-hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-80 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="h-screen flex justify-between grow flex-col gap-y-1 bg-white dark:bg-black pb-4">
          <div className="flex px-6 py-6 shrink-0 items-center">
            <Logo showText={true} size={LogoSize.SMALL} />
          </div>

          <div className="overflow-auto px-6">
            <SidebarAccordion
              deleteThread={deleteThread}
              handleThreadClick={handleThreadClick}
              refreshThreads={refreshThreads}
            />
          </div>
        </div>
      </div>

      <div
        className={`lg:pl-80 flex flex-col ${
          isLoaded && isSignedIn && !termsAccepted ? "" : "h-[92dvh] lg:h-dvh"
        } bg-gray-100 dark:bg-gray-600`}
      >
        <div className="fixed top-0 block lg:hidden sticky z-40 shrink-0 pt-6 pb-3 px-6 bg-gray-100 dark:bg-gray-600">
          <div className="bg-white block flex h-[52px] shrink-0 justify-between items-center w-full gap-x-4 bg-white px-4 shadow-md gap-x-6 rounded-full">
            <Logo showText={true} size={LogoSize.SMALL} mobileView={true} />
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        {isLoaded && isSignedIn ? (
          <>
            {!termsAccepted ? (
              !loading ? (
                <main className="relative min-h-full flex p-10 items-center bg-gray-100 dark:bg-gray-600">
                  <ToSConsent handleAccept={acceptTerms} />
                </main>
              ) : (
                <main className="relative h-full bg-gray-100 dark:bg-gray-600"></main>
              )
            ) : (
              <main className="pb-5 sm:pb-0 h-[82dvh] lg:h-dvh relative bg-gray-100 dark:bg-gray-600">
                <div className="relative bg-gray-100 dark:bg-gray-600 z-10 px-4 sm:px-6 lg:px-8 pt-4 h-full">
                  <div className="overflow-auto flex flex-col justify-end w-full ml-auto mr-auto stretch h-full">
                    <div
                      ref={messageContainerRef}
                      className="flex items-stretch pr-2 overflow-auto overflow-x-hidden duration-200"
                    >
                      <div
                        ref={signifierContainerRef}
                        className="min-w-[60px] sm:min-w-[100px] relative"
                      >
                        <div
                          ref={signifierRef}
                          className={`absolute ease-out transition-all duration-500 ${
                            !termsAccepted &&
                            messages.length &&
                            historicalMessages.length
                              ? "top-0"
                              : ""
                          }`}
                        >
                          {messages.length || historicalMessages.length ? (
                            status === "in_progress" ? (
                              <ThinkingSignifier />
                            ) : (
                              <IdleSignifier />
                            )
                          ) : selectedStage === null ? (
                            <IdleSignifier />
                          ) : (
                            <ThinkingSignifier />
                          )}
                        </div>
                      </div>
                      <div className={`flex-1 pb-12`}>
                        <div
                          ref={messagesListRef}
                          className="space-y-6 pb-4"
                          aria-live="polite"
                        >
                          <ChatIntro
                            selectedStage={selectedStage}
                            frozen={
                              threadId ? true : messages.length ? true : false
                            }
                            handleStageSelected={handleSelectStage}
                          />

                          {historicalMessages.map((m: Message) => (
                            <ChatMessage
                              message={m}
                              key={m.id}
                              handleModalOpen={() =>
                                openFeedbackModal(m.content, m.id)
                              }
                              startNewChat={startNewChat}
                            />
                          ))}
                          {messages.map((m: Message) => (
                            <ChatMessage
                              message={m}
                              key={m.id}
                              handleModalOpen={() =>
                                openFeedbackModal(m.content, m.id)
                              }
                              startNewChat={startNewChat}
                            />
                          ))}
                        </div>

                        {/*status === "in_progress" && (
                    <div className="mt-5">
                      <Loading />
                    </div>
                  )*/}
                      </div>
                    </div>

                    {termsAccepted ? (
                      <>
                        <div className="sticky">
                          <ChatInput
                            input={input}
                            handleInputChange={(e) => {
                              handleInputChange(
                                e as React.ChangeEvent<HTMLTextAreaElement>
                              );
                            }}
                            handleSubmit={(e: any) => {
                              if (status !== "in_progress") {
                                submitMessage(e);
                              }
                            }}
                          />
                        </div>
                        <div className="mx-auto max-w-[250px] w-full py-3 lg:hidden">
                          <Legal isDarkMode={isDarkMode} />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </main>
            )}
          </>
        ) : (
          <main className="relative flex-1 pt-10 px-10 items-center bg-gray-100 dark:bg-gray-600">
            <div>
              <h1 className="text-xl sm:text-h1 text-orange dark:text-orange-200">
                <span className="inline-block">Hello, welcome to your</span>{" "}
                <span className="inline-block">small business</span>{" "}
                <span className="inline-block">mentorship journey.</span>
              </h1>
              <h2 className="text-xl sm:text-h2 font-light dark:text-white text-black">
                <span className="inline-block">You need to be signed in</span>{" "}
                <span className="inline-block">to get started.</span>
              </h2>
            </div>
            <div className="flex space-x-4 pt-10">
              <div className="max-w-[155px] w-full">
                <Button
                  className="flex justify-center items-center w-full"
                  onClick={() => {
                    setSidebarOpen(true);
                    setAuthState("signup");
                    setIsProfileDropdownOpen(true);
                  }}
                >
                  Get started
                </Button>
              </div>
              <div className="max-w-[109px] w-full">
                <Button
                  className="flex justify-center items-center w-full"
                  onClick={() => {
                    setSidebarOpen(true);
                    setAuthState("signin");
                    setIsProfileDropdownOpen(true);
                  }}
                >
                  Login
                </Button>
              </div>
            </div>
          </main>
        )}
      </div>
      <FeedbackModal
        isOpen={feedbackModalOpen}
        setIsOpen={setFeedbackModalOpen}
        context={feedbackModalContext}
      />
    </div>
  );
}
