import Lottie from "lottie-react"
import animationData from "@/components/lottie/Ripple_Thinking.json"

export default function ThinkingSignifier() {
  return (
    <div className="-m-10">
      <Lottie
        aria-hidden="true"
        animationData={animationData}
        loop={true}
      />
    </div>
  )
}
