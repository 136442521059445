import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

export function a(props: any) {
  const { children, href } = props
  const url = (href as string).startsWith("http") ? href : `https://${href}`
  return <a
    href={url}
    target='_blank'
    rel='noopener'
    className="underline text-black dark:text-white font-medium"
  >
    {children}
  </a>
}

export function code(props: any) {
  const { children, className, ...rest } = props
  const match = /language-(\w+)/.exec(className || "")

  return (
    <div className='py-2 overflow-auto'>
      {match ? (
        <SyntaxHighlighter
          {...rest}
          PreTag="div"
          language={match[1]}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code>
          {children}
        </code>
      )}
    </div>
  )
}

export function h1(props: any) {
  const { children } = props
  return <h1 className='py-2 text-2xl'>
    {children}
  </h1>
}

export function h2(props: any) {
  const { children } = props
  return <h2 className='py-2 text-xl'>
    {children}
  </h2>
}

export function h3(props: any) {
  const { children } = props
  return <h3 className='py-2 text-lg'>
    {children}
  </h3>
}

export function hr() {
  return <hr className='my-2' />
}

export function li(props: any) {
  const { children } = props
  return <li className="m-0 p-0 whitespace-normal">
    {children}
  </li>
}

export function ol(props: any) {
  const { children } = props
  return <ol className="m-0 p-0 list-decimal list-outside ml-4">
    {children}
  </ol>
}

export function ul(props: any) {
  const { children } = props
  return <ol className="m-0 p-0 list-disc ml-6">
    {children}
  </ol>
}

export function p(props: any) {
  const { children } = props
  return <p className="py-2 whitespace-normal">
    {children}
  </p>
}
