import { IconProps } from "@/utils/types"

export function FAQIcon(props: IconProps) {
  return (
    <div {...props}>
      <svg className="dark:fill-white fill-black" width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.9602 18H3.96021C2.03021 18 0.460205 16.43 0.460205 14.5V3.5C0.460205 1.57 2.03021 0 3.96021 0H20.9602C22.8902 0 24.4602 1.57 24.4602 3.5V14.5C24.4602 16.43 22.8902 18 20.9602 18ZM3.96021 1C2.58221 1 1.46021 2.121 1.46021 3.5V14.5C1.46021 15.879 2.58221 17 3.96021 17H20.9602C22.3382 17 23.4602 15.879 23.4602 14.5V3.5C23.4602 2.121 22.3382 1 20.9602 1H3.96021Z" fill="inherit"/>
        <path d="M8.45631 9.437H5.34131V11.999H4.37231V6H8.65331V6.917H5.34131V8.52H8.45631V9.437Z" fill="inherit"/>
        <path d="M14.5752 11.999H13.5332L12.9422 10.47H10.2262L9.6352 11.999H8.6272L10.9972 6H12.2052L14.5752 11.999ZM10.5812 9.553H12.5862L11.5832 6.965L10.5812 9.553Z" fill="inherit"/>
        <path d="M17.7333 5.88867C18.1933 5.88867 18.6163 5.96667 19.0023 6.12167C19.3873 6.27767 19.7193 6.49367 19.9963 6.77067C20.2733 7.04767 20.4893 7.37567 20.6433 7.75567C20.7973 8.13567 20.8743 8.55067 20.8743 8.99867C20.8743 9.39067 20.8143 9.75467 20.6963 10.0917C20.5783 10.4287 20.4113 10.7317 20.1973 10.9997L20.9433 11.9977H19.8073L19.5163 11.6117C19.2653 11.7717 18.9903 11.8947 18.6913 11.9807C18.3933 12.0657 18.0733 12.1087 17.7333 12.1087C17.2733 12.1087 16.8503 12.0317 16.4643 11.8757C16.0793 11.7207 15.7473 11.5037 15.4703 11.2267C15.1933 10.9507 14.9773 10.6217 14.8233 10.2417C14.6693 9.86167 14.5923 9.44767 14.5923 8.99967C14.5923 8.55167 14.6693 8.13667 14.8233 7.75667C14.9773 7.37667 15.1933 7.04867 15.4703 6.77167C15.7473 6.49467 16.0793 6.27867 16.4643 6.12267C16.8513 5.96667 17.2733 5.88867 17.7333 5.88867ZM17.7333 6.81767C17.4273 6.81767 17.1453 6.87167 16.8843 6.97667C16.6243 7.08167 16.4003 7.23067 16.2123 7.42167C16.0243 7.61267 15.8763 7.84367 15.7683 8.11067C15.6613 8.37967 15.6073 8.67467 15.6073 8.99867C15.6073 9.32067 15.6613 9.61667 15.7683 9.88567C15.8753 10.1547 16.0233 10.3847 16.2123 10.5747C16.4003 10.7667 16.6253 10.9157 16.8843 11.0207C17.1443 11.1257 17.4273 11.1787 17.7333 11.1787C17.9623 11.1787 18.1763 11.1497 18.3763 11.0907C18.5763 11.0317 18.7623 10.9467 18.9333 10.8357L17.6953 9.18967H18.8523L19.5683 10.1537C19.6593 9.99167 19.7313 9.81367 19.7823 9.62067C19.8333 9.42867 19.8593 9.22067 19.8593 8.99767C19.8593 8.67467 19.8053 8.37867 19.6983 8.10967C19.5913 7.84167 19.4433 7.61167 19.2543 7.42067C19.0653 7.22967 18.8413 7.08067 18.5823 6.97567C18.3223 6.87167 18.0393 6.81767 17.7333 6.81767Z" fill="inherit"/>
      </svg>
    </div>
  )
}
