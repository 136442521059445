import { IconProps } from "@/utils/types"

interface NewChatIconProps extends IconProps {
  fill?: string;
}

export function NewChatIcon(props: NewChatIconProps) {
  const fill = props.fill ?? "#141413"

  return (
    <div {...props}>
      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.7424 1.7572L10.1341 1.14858H12.3264V0.637939H9.26255V3.70177H9.77319V1.50954L10.3815 2.11815C11.4184 3.15475 11.9894 4.53315 11.9894 5.99964C11.9894 7.46613 11.4184 8.84453 10.3815 9.88113C9.34489 10.918 7.96649 11.489 6.5 11.489V11.9996C8.10277 11.9996 9.60947 11.3757 10.7424 10.2421C11.8761 9.10911 12.5 7.60241 12.5 5.99964C12.5 4.39688 11.8761 2.89017 10.7424 1.7572Z" fill={fill} />
        <path d="M3.22681 10.4899L2.61819 9.88125C1.5816 8.84465 1.01064 7.46625 1.01064 5.99976C1.01064 4.53359 1.5816 3.15486 2.61851 2.11827C3.65511 1.08135 5.03383 0.510394 6.5 0.510394V-0.000244141C4.89723 -0.000244141 3.39053 0.623692 2.25723 1.75699C1.12394 2.89029 0.5 4.39699 0.5 5.99976C0.5 7.60252 1.12426 9.10922 2.25723 10.2422L2.86585 10.8508H0.673617V11.3615H3.73745V8.29763H3.22681V10.4899Z" fill={fill} />
      </svg>
    </div>
  )
}

