import { useEffect, useState } from "react"
import Image from "next/image"
import { trackStageSelection } from "@/utils/analytics";

const topics = [
  {
    label: 'Ideating a Business',
    img: '/images/ideating.png',
    message: "I'm at the ideating stage.",
  },
  {
    label: 'Starting a Business',
    img: '/images/starting.png',
    message: "I'm at the starting stage.",
  },
  {
    label: 'Sustaining a Business',
    img: '/images/sustaining.png',
    message: "My business is at the sustaining stage.",
  },
  {
    label: 'Growing a Business',
    img: '/images/growing.png',
    message: "My business is at the growth stage.",
  },
]

interface ChatIntroInterface {
  selectedStage: number | null;
  frozen?: boolean;
  handleStageSelected: (selectedStage: number, stageMessage: string) => Promise<void>
}

export default function ChatIntro({ selectedStage, frozen, handleStageSelected }: ChatIntroInterface) {
  const [selectedIdx, setSelectedIdx] = useState(-1)

  function handleSelection(idx: number) {
    if (frozen) return;
    setSelectedIdx(idx)
    handleStageSelected(idx, topics[idx].message)

    // Track chat click
    trackStageSelection(topics[idx].label.toLowerCase().replaceAll(' ', '_'))
  }

  useEffect(() => {
    if (selectedStage === null) {
      setSelectedIdx(-1)
    }
  }, [selectedStage])

  return (
    <div className={`pt-4 sm:pt-6 pl-2 sm:px-0 flex space-x-4`}>
      <div className="space-y-10">
        <div>
          <h1 className="text-xl max-w-[calc(100vw-150px)] sm:max-w-full sm:text-h1 text-orange dark:text-orange-200">
            <span className="inline-block">Let&apos;s get started.</span>
          </h1>
          <h2 className="text-xl sm:text-h2 font-light dark:text-white text-black">
            <span className="inline-block">I&apos;ve got a few topics</span>{' '}
            <span className="inline-block">we can explore</span>{' '}
            <span className="inline-block">or ask me a business question.</span>
          </h2>
        </div>

        <div className={`py-2 pb-4 sm:pb-2 flex space-x-4 max-w-[calc(100vw-100px)] sm:max-w-full overflow-auto ${(selectedIdx === -1 && selectedStage === null) ? 'justify-start' : 'justify-end'}`}>
          {selectedStage !== null
            ? <div
              className={`bg-white dark:bg-black rounded-xl p-4 min-w-[120px] max-w-[120px] hover:shadow-md cursor-pointer`}
            >
              <Image
                width={100}
                height={100}
                src={topics[selectedStage].img}
                alt={topics[selectedStage].label}
              />
              <p className="mt-2 text-black dark:text-white">
                <span className="text-orange dark:text-orange-200">
                  {topics[selectedStage].label.split(' ')[0]}
                </span>
                {' '}{topics[selectedStage].label.split(' ').splice(1).join(' ')}
              </p>
            </div>
            :
            (topics.map((topic, idx) => {
              const arr = topic.label.split(' ')
              return (
                <div
                  key={idx}
                  className={`${selectedIdx === -1 ? 'visible' : selectedIdx === idx ? 'visible' : 'hidden'} bg-white dark:bg-black rounded-xl p-4 min-w-[120px] max-w-[120px] hover:shadow-md cursor-pointer`}
                  onClick={() => handleSelection(idx)}
                >
                  <Image
                    width={100}
                    height={100}
                    src={topic.img}
                    alt={topic.label}
                  />
                  <p className="mt-2 text-black dark:text-white">
                    <span className="text-orange dark:text-orange-200">
                      {arr[0]}
                    </span>
                    {' '}{arr.splice(1).join(' ')}
                  </p>
                </div>
              )
            }))
          }
        </div>
      </div>
    </div>
  )
}
