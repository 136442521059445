import { useState, useEffect } from "react";
import { useAssistant as useOriginalAssistant, Message } from "ai/react";

function useAssistant(options: any) {
  const [clearMessages, setClearMessages] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const assistant = useOriginalAssistant(options);

  useEffect(() => {
    setMessages(assistant.messages);
  }, [assistant.messages]);

  useEffect(() => {
    if (clearMessages) {
      setMessages([]);
      setClearMessages(false);
    }
  }, [clearMessages]);

  return { ...assistant, messages, clearMessages: () => setClearMessages(true) };
}

export default useAssistant;
